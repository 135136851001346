import { useMemo } from "react";
import { V3_UI_LOCAL_STORAGE_KEY } from "@circle-react/components/constants";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useLocalStorageState } from "@circle-react/hooks/utils/useLocalStorageState";

export interface UseIsV3Props {
  iconSize: number;
  isV3Enabled: boolean;
  isCircleV3Preview: boolean;
  shouldShowPostModal: boolean;
}

const isAppOrLoginSubdomain = (): boolean => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  const subdomain = parts.length > 2 ? parts[0] : null;
  return subdomain === "app" || subdomain === "login";
};

export const useIsV3 = () => {
  const { search, pathname } = location;
  const params = new URLSearchParams(search);
  const [isV3EnabledFromLocalStorage, setIsV3EnabledFromLocalStorage] =
    useLocalStorageState(V3_UI_LOCAL_STORAGE_KEY);
  const { currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();
  const isCurrentMemberAdmin = isCommunityAdmin(currentCommunityMember);

  const {
    circle_v3_enabled: isV3EnabledFromFeatureFlag,
    circle_v3_preview: isCircleV3Preview,
  } = currentCommunitySettings ?? {};

  const isV3EnabledFromParams = params.get(V3_UI_LOCAL_STORAGE_KEY) === "true";
  const isV3DisabledFromParams =
    params.get(V3_UI_LOCAL_STORAGE_KEY) === "false";

  const shouldEnableV3PreviewForAdminsIfEnabled =
    isCircleV3Preview && isCurrentMemberAdmin;

  const isV3Enabled = useMemo(() => {
    if (
      isAppOrLoginSubdomain() ||
      isV3EnabledFromFeatureFlag ||
      shouldEnableV3PreviewForAdminsIfEnabled
    ) {
      return true;
    }

    if (isV3EnabledFromParams) {
      setIsV3EnabledFromLocalStorage(true);
      return true;
    }

    if (isV3DisabledFromParams) {
      setIsV3EnabledFromLocalStorage(false);
      return false;
    }

    if (isV3EnabledFromLocalStorage !== undefined) {
      return Boolean(isV3EnabledFromLocalStorage);
    }

    return false;
  }, [
    isV3EnabledFromLocalStorage,
    isV3EnabledFromFeatureFlag,
    isV3EnabledFromParams,
    isV3DisabledFromParams,
    setIsV3EnabledFromLocalStorage,
    shouldEnableV3PreviewForAdminsIfEnabled,
  ]);
  /*
   * The Checkout component is mounted independently via checkout/show.html.erb
   * using the react_mount_controller.js. Since it is not part of the MainApp, it
   * does not include the BrowserRouter provider from react-router-dom. As a result,
   * the useRouteMatch() method fails because the router context is missing. And hence,
   * using useRouteMatch() for `isPostDetails` fails in the checkout page scenario, and hence
   * we are using a REGEX based pattern matching for the post details page route.
   */
  const isPostDetailsPage = /^\/c\/[^/]+\/[^/]+\/?$/.test(pathname);
  const shouldShowPostModal = isV3Enabled && !isPostDetailsPage;

  const iconSize = isV3Enabled ? 20 : 16;

  const settings: UseIsV3Props = {
    iconSize,
    isV3Enabled,
    isCircleV3Preview,
    shouldShowPostModal,
  };

  return settings;
};
