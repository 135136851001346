export const isValidURL = (str: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol (required)
      "(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+" + // domain (with subdomain(s)) or IP v4
      "[a-z\\d]{2,}" + // top-level domain or IP v4 last block
      "(\\:\\d+)?" + // port
      "(\\/[\\-;:&=\\+\\$,\\w\\.]*\\/?)*" + // path, allowing for a trailing slash
      "(\\?[\\-;:&=\\+\\$,\\w\\.]*)?" + // query string
      "(\\#[\\-/\\w]*)?$", // fragment locator, adjusted to allow more characters
    "i", // case insensitive
  ); // fragment locator

  return !!pattern.test(str);
};
